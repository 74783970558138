<template>
  <b-card-code title="Form Detail Data Aset">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nama Aset">
              <validation-provider
                #default="{ errors }"
                name="Nama Aset"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.name"
                  placeholder="Nama Aset"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Aset Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Lokasi/Alamat">
              <validation-provider
                #default="{ errors }"
                name="Lokasi/Alamat"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.address"
                  placeholder="Lokasi/Alamat"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Lokasi/Alamat Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Booking">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Booking"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.booking_payment_date"
                  class="form-control"
                  placeholder="Tanggal Booking"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Booking Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Jumlah Booking">
              <validation-provider
                #default="{ errors }"
                name="Jumlah Booking"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.booking_payment_amount"
                  placeholder="Jumlah Booking"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah Booking Wajib Diisi" : ""
                }}</small>
                <small v-if="formSubmit.price"> Rp. {{ booking_payment_amount2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal DP">
              <validation-provider
                #default="{ errors }"
                name="Tanggal DP"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.down_payment_date"
                  class="form-control"
                  placeholder="Tanggal DP"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal DP Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Jumlah DP">
              <validation-provider
                #default="{ errors }"
                name="Jumlah DP"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.down_payment_amount"
                  placeholder="Jumlah DP"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah DP Wajib Diisi" : ""
                }}</small>
                <small v-if="formSubmit.price"> Rp. {{ down_payment_amount2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Harga">
              <validation-provider
                #default="{ errors }"
                name="Harga"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.price"
                  placeholder="Harga"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Harga Wajib Diisi" : ""
                }}</small>

                <small v-if="formSubmit.price"> Rp. {{ price2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Jatuh Tempo">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Jatuh Tempo"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.due_date"
                  class="form-control"
                  placeholder="Tanggal Jatuh Tempo"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Jatuh Tempo Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Telepon Kontak">
              <validation-provider
                #default="{ errors }"
                name="Telepon Kontak"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.contact_phone"
                  placeholder="Telepon Kontak"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Telepon Kontak Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Nama Kontak">
              <validation-provider
                #default="{ errors }"
                name="Nama Kontak"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.contact_name"
                  placeholder="Nama Kontak"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Kontak Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formSubmit.note"
                placeholder="Masukkan Catatan"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="6" class="mt-2">
            <b-form-checkbox v-model="formSubmit.is_cancel" disabled>
              Batal
            </b-form-checkbox>
          </b-col>
        </b-row>

        <!-- table dokumen Aset hanya saat edit data saja -->
        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> Daftar Dokumen Aset </b></b-col
          >
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columnsDocumentAset"
              :rows="rowsDocumentAset"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'document_type'">
                  {{ props.row.document_type.replaceAll("_", " ") }}
                </span>

                <span v-if="props.column.field === 'upload_date'">
                  {{
                    props.row.upload_date === null
                      ? "-"
                      : props.row.upload_date.substring(0, 10)
                  }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    v-if="props.row.document_url == null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Download Dokumen"
                    class="btn-icon"
                    @click="downloadNull(props)"
                  >
                    <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-if="props.row.document_url != null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Download Dokumen"
                    class="btn-icon"
                    @click="downloadDokumen(props)"
                  >
                    <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <!-- end table dokumen Aset -->

        <!-- table History Pembayaran Aset hanya saat edit data saja -->
        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> History Pembayaran Aset </b></b-col
          >

          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2"> </b-col>
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columnspayment_history"
              :rows="rowspayment_history"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'payment_name'">
                  {{ props.row.payment_name }}
                </span>

                <span v-if="props.column.field === 'note'">
                  {{ props.row.note }}
                </span>

                <span v-if="props.column.field === 'payment_date'">
                  {{ props.row.payment_date.substring(0, 10) }}
                </span>

                <span v-if="props.column.field === 'payer_name'">
                  {{ props.row.payer_name }}
                </span>

                <span v-if="props.column.field === 'payment_amount'">
                  Rp.
                  {{
                    props.row.payment_amount
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <!-- end table History Pembayaran Aset -->

        <!-- button -->

        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      FormPembayaranAset: "",

      statusOptions: [
        {
          _id: "KAWIN",
          name: "KAWIN",
        },
        {
          _id: "BELUM_KAWIN",
          name: "BELUM KAWIN",
        },
        {
          _id: "JANDA",
          name: "JANDA",
        },
        {
          _id: "DUDA",
          name: "DUDA",
        },
      ],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      titleForm: "",
      price2: "",
      down_payment_amount2: "",
      booking_payment_amount2: "",

      lastPathParams: "",

      formSubmit: {
        name: "",
        address: "",
        price: "",
        due_date: "",
        down_payment_date: "",
        down_payment_amount: "",
        contact_phone: "",
        contact_name: "",
        note: "",
        booking_payment_date: "",
        booking_payment_amount: "",
        is_cancel: false,
      },

      formDetail: {
        kavling_name: "",
        block_name: "",
      },

      id_asset: "",
      block_name: "",
      kavling_name: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",

      total_paid: "",
      total_unpaid: "",

      rowsDocumentAset: [],
      columnsDocumentAset: [
        {
          label: "Nama Dokumen",
          field: "document_type",
          thClass: "text-center",
        },
        {
          label: "Tanggal Upload",
          field: "upload_date",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],

      rowspayment_history: [],
      columnspayment_history: [
        {
          label: "Tipe Pembayaran",
          field: "payment_name",
          thClass: "text-center",
        },
        {
          label: "Tanggal Bayar",
          field: "payment_date",
          thClass: "text-center",
        },
        {
          label: "Jumlah Bayar",
          field: "payment_amount",
          thClass: "text-center",
        },
        {
          label: "Dibayar Oleh",
          field: "payer_name",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
        },
      ],

      formDisabledPayment: {},

      formPaymentHistory: {
        payment_date: "",
        payment_type: "",
        payment_name: "",
        payment_amount: "",
        payer_name: "",
        note: "",
      },

      formDokumen: null,
      id_dokumen: "",

      aksiPembayaranAset: "",
      idPembayaranAset: "",

      paymentTypeOptions: [],

      // end
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    this.getPaymentType();

    if (this.lastPathParams != "form") {
      this.getIdData();
      this.titleForm = "Form Update Data Aset";
    } else {
      this.titleForm = "Form Tambah Aset Baru";
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    submitValidate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.lastPathParams === "form") {
            return new Promise(() => {
              axios
                .post("v1/asset", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Aset Berhasil`,
                    },
                  });
                  this.$router.push("/aset");
                  this.$refs["ref-form-data-aset"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/asset/" + this.$route.params.id, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Aset Berhasil`,
                    },
                  });
                  this.$router.push("/aset");
                  this.$refs["ref-form-data-aset"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          }
        }
      });
    },

    getIdData() {
      return new Promise(() => {
        axios
          .get("v1/asset/" + this.$route.params.id)
          .then((res) => {
            this.id_asset = res.data._id;
            this.formSubmit.is_cancel = res.data.is_cancel;
            this.formSubmit.name = res.data.name;
            this.formSubmit.address = res.data.address;
            this.formSubmit.price = res.data.price;
            this.formSubmit.down_payment_date = res.data.down_payment_date;
            this.formSubmit.down_payment_amount = res.data.down_payment_amount;
            this.formSubmit.contact_phone = res.data.contact_phone;
            this.formSubmit.contact_name = res.data.contact_name;
            this.formSubmit.note = res.data.note;
            this.formSubmit.due_date = res.data.due_date;
            this.formSubmit.booking_payment_date =
              res.data.booking_payment_date;
            this.formSubmit.booking_payment_amount =
              res.data.booking_payment_amount;

            this.rowsDocumentAset = res.data.documents;
            this.rowspayment_history = res.data.payment_history;

            this.handleInputHarga();
            this.handleInputBooking();
            this.handleInputDp();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    showModalTambahPembayaran() {
      this.payment_date = "";
      this.payment_type = "";
      this.payment_name = "";
      this.payment_amount = "";
      this.payer_name = "";
      this.note = "";

      this.FormPembayaranAset = "Form Pembayaran Aset";

      this.$refs["ref-form-pembayaran-aset"].show();
      this.aksiPembayaranAset = "tambah";
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get("v1/asset/" + this.id_asset + "/" + props.row.id)
          .then((res) => {
            this.idPembayaranAset = res.data.id;

            this.formPaymentHistory.payment_date = res.data.payment_date.substr(
              0,
              10
            );
            this.formPaymentHistory.payment_type = res.data.payment_type;
            this.formPaymentHistory.payment_name = res.data.payment_name;
            this.formPaymentHistory.payment_amount = res.data.payment_amount;
            this.formPaymentHistory.payer_name = res.data.payer_name;
            this.formPaymentHistory.note = res.data.note;

            this.FormPembayaranAset = "Form Update Pembayaran Aset";

            this.aksiPembayaranAset = "ubah";
            this.$refs["ref-form-pembayaran-aset"].show();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    submitFormPembayaran() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.aksiPembayaranAset === "tambah") {
            return new Promise(() => {
              axios
                .post(
                  "v1/asset/" + this.id_asset + "/payment",
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Pembayaran Aset Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-pembayaran-aset"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put(
                  "v1/asset/" + this.id_asset + "/" + this.idPembayaranAset,
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Pembayaran Aset Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-pembayaran-aset"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          }
        }
      });
    },

    kembaliModalForm() {
      this.$refs["ref-form-pembayaran-aset"].hide();
    },

    uploadDokumen(props) {
      this.id_dokumen = props.row.id;
      this.$refs["ref-form-upload-dokumen"].show();
    },

    handleFileUpload(event) {
      this.formDokumen = event.target.files;
    },

    submitFileDokumen() {
      this.$refs.simpleRulesDokumen.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("uploaded_document", this.formDokumen);

          return new Promise(() => {
            axios
              .post(
                "v1/asset/" +
                  this.id_asset +
                  "/upload_document?document_id=" +
                  this.id_dokumen,
                formData
              )
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Upload Dokumen Berhasil`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: `Upload Dokumen Aset Berhasil`,
                  },
                });

                this.getIdData();
                this.$refs["ref-form-upload-dokumen"].hide();
              });
          });
        }
      });
    },

    kembaliModalFormDokumen() {
      this.$refs["ref-form-upload-dokumen"].hide();
    },

    downloadDokumen(props) {
      window.open(
        "https://api-bsgproperti.mrizkyff.com/v1/asset/" +
          this.id_asset +
          "/download_document/" +
          props.row.id
      );
    },

    downloadNull(props) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title:
            "File Dokumen " +
            props.row.document_type.replaceAll("_", " ") +
            " Tidak Tersedia",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang sudah terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/asset/" + this.id_asset + "/" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Pembayaran Aset Berhasil`,
              },
            });
            this.getIdData();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.price);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputBooking() {
      this.booking_payment_amount2 = this.format2(this.formSubmit.booking_payment_amount);
      this.$emit("input", (this.booking_payment_amount2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputDp() {
      this.down_payment_amount2 = this.format2(this.formSubmit.down_payment_amount);
      this.$emit("input", (this.down_payment_amount2 + "").replace(/[^0-9]/g, ""));
    },

    getPaymentType() {
      return new Promise(() => {
        axios.get("v1/asset/combo_payment_type").then((res) => {
          this.paymentTypeOptions = res.data;
        });
      });
    },

    backButton() {
      this.$router.push("/aset");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
